@import './_vars.scss';

/********************
 *Bootstrap Overide**
*********************/

.navbar,
.navbar-default,
nav,
.nav {
	z-index: 999;
	box-shadow: none !important;
}

.navbar {
	border-radius: 0px !important;
	margin-bottom: 0px !important;
	font-family: 'Bad Script', cursive;
	background-color: rgba(0, 0, 0, 0.3);
}

.navbar-right {
	margin-right: -30px;
}

.navbar-brand-inner {
	margin-top: 6px !important;
}

@media (min-width: 521px) {
	.navbar-brand {
		height: 100px !important;
		font-size: 22pt !important;
		padding: 0 !important;
		margin-left: 0px !important;
	}

	.navbar-brand img {
		height: auto;
		max-height: 100px;
		max-width: 180px;
		margin: auto;
		padding: 5px !important;
	}
}

@media (max-width: 520px) {
	.navbar-brand {
		height: 100px !important;
		font-size: 22pt !important;
		padding: 0 !important;
		margin-left: 0px !important;
	}

	.navbar-brand img {
		height: auto;
		max-width: 180px;
		max-height: 100px;
		margin: auto;
		padding: 5px !important;
	}
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: $secondary-color;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-brand {
	color: #FFD700;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.dropdown-menu {
	background-color: #000;
}

.dropdown-menu > li > a {
	padding: 15px;
	font-size: 14pt;
	color: $primary-color;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	color: $secondary-color;
	text-decoration: none;
}

.navbar-default .navbar-nav > li > a {
	color: $primary-color;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: $secondary-color;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
	/* ...and now override with proper CSS property */
	transition:color .4s ease-out, background .3s ease-in;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: $secondary-color;
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
	color: #fff;
	background-color: transparent;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	color: $primary-color;
}

.navbar-toggle {
	margin-top: 33px !important;
}

@media (min-width: $dropdown-header-max-width) {
	.navbar-nav > li > a {
		padding-top: 40px !important;
		padding-bottom: 40px !important;
	}

	.nav > li > a {
		padding-left: 30px !important;
		padding-right: 30px !important;
		font-size: 18pt !important;
	}
}

@media(max-width: $dropdown-header-min-width) {
	.nav > li > a {
		padding: 15px 10px !important;
		font-size: 18pt !important;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: $primary-color;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #000000;
	}

	.navbar-nav .open .dropdown-menu {
		position: static;
		float: none;
		width: auto;
		margin-top: 0;
		background-color: transparent;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.navbar-nav .open .dropdown-menu > li > a {
		line-height: 20px;
	}
	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px;
	}
	.navbar-header {
		float: none;
	}
	.navbar-toggle {
		display: block;
	}
	.navbar-collapse {
		border-top: 1px solid transparent;
		box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
	}
	.navbar-collapse.collapse {
		display: none!important;
	}
	.navbar-nav {
		float: none!important;
		/*margin: 7.5px -15px;*/
		margin: 7.5px 50px 7.5px -15px
	}
	.navbar-nav>li {
		float: none;
	}
	.navbar-nav>li>a {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.navbar-text {
		float: none;
		margin: 15px 0;
	}
	/* since 3.1.0 */
	.navbar-collapse.collapse.in {
		display: block!important;
		overflow-y: visible !important;
	}
	.collapsing {
		overflow: hidden!important;
	}
	.navbar-default .navbar-collapse {
		border-color: transparent;
	}
}

/*************
 *Global CSS**
**************/

html {
	-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 2s; /* Firefox < 16 */
	-ms-animation: fadein 2s; /* Internet Explorer */
	-o-animation: fadein 2s; /* Opera < 12.1 */
	animation: fadein 2s;
}

html, body {
	height: 100%;
}

body {
	margin: 0;
	padding: 0;
	text-align: left;
	background-color: #F0F0F0;
	color: #000000;
	font-weight: normal;
	overflow-x: hidden;
	overflow-y: auto;
	min-height: 100%;
	font-family: 'Open Sans', sans-serif;
}

img {
	max-width: 100%;
}

@media (min-width: 401px) {
	section#main {
		float: left;
		position: relative;
		width: 100%;
		padding-bottom: 254px;
	}
}

@media (max-width: 400px) {
	section#main {
		float: left;
		position: relative;
		width: 100%;
		padding-bottom: 274px;
	}
}

#container {
	overflow-x: hidden;
	position: relative;
	margin: 0 auto;
	height: auto !important;
	min-height: 100%;
	height: 100%;
}

.clear:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.navbar {
	margin-bottom: 0px !important;
	border-width: 0 0 1px 0;
	border-color: transparent;
}

.table {
	background-color: #FFFFFF;
}
