$primary-color:           #af8658;
$secondary-color:         #fff820;

$footer-color:            #ffffff;
$footer-background-color: #000000;

$dropdown-header-min-width:   1065px;
$dropdown-header-max-width:   $dropdown-header-min-width - 1;
$max-width-xs:                767px;
$min-width-from-xs:           $max-width-xs + 1;
