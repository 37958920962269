@import '../_vars.scss';
@import '../_bootstrap.scss';
@import '../returnToTop.scss';
@import '../_bootstrapOverrides.scss';
@import '../../css/justified-gallery.css';

/********************
 *Index Section CSS**
 ********************/

.overlay {
	position: absolute;
	width: 100%;
	height: 100vh;
	z-index: 2;
	background-color: #080d15;
	opacity: .5;
}

.splash-text-container-outer {
	display: table;
	height: 100%;
	width: 100%;
	margin-top: -100px;
	padding-top: 100px;
	float: left;
}

.splash-text-container {
	text-align: center;
	display: table-cell;
	vertical-align: middle;
	color: #ffffff;
	z-index: 99;
	position: relative;
	padding: 0 10px;
}

.splash-text-container a {
	color: $primary-color;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

.splash-text-container a:hover {
	color: $secondary-color;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

img.header-image {
	width: 200px;
	display: block;
	margin: auto;
}

.splash-text-container p.main-message {
	display: block;
}

.splash-text-container p.sub-message {
	display: block;
}

@media(min-width: $dropdown-header-min-width) {
	.splash-text-container p.main-message {
		font-size: 50pt;
	}

	.splash-text-container p.sub-message {
		font-size: 26pt;
	}

	.dropdown-header {
		font-size: 26pt !important;
	}
	.dropdown-menu-large a {
		font-size: 14pt;
	}
	.dropdown-menu-large .dropdown-header {
		color: $secondary-color;
	}
	.dropdown-menu-large > li > ul > li > a {
		color: #333333;
	}
}

@media(max-width: $dropdown-header-max-width) {
	.splash-text-container p.main-message {
		font-size: 30pt;
	}

	.splash-text-container p.sub-message {
		font-size: 20pt;
	}

	.dropdown-menu-large > li > ul > li > a {
		color: #FFFFFF;
		font-size: 16pt;
		line-height: 1.5;
	}
	.dropdown-menu-large .dropdown-header {
		color: $primary-color;
		font-size: 18pt;
		padding: 3px 15px !important;
	}
	.dropdown-menu-large {
		margin-left: 0 ;
		margin-right: 0 ;
	}
	.dropdown-menu-large > li {
		margin-bottom: 30px;
	}
	.dropdown-menu-large > li:last-child {
		margin-bottom: 0;
	}
	.navbar-default .navbar-nav > .open > a {
		font-size: 18pt;
	}
}

/*************
 *Footer CSS**
**************/

footer {
	bottom: 0;
	position: absolute;
	background-color: $footer-background-color;
	color: $footer-color;
	width: 100%;
	padding-top: 10px;
	display: block;
}

footer a {
	color: $primary-color;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer a:hover {
	color: $secondary-color;
	text-decoration: none;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer .fab {
	font-size: 42pt;
}

footer .fa-facebook,
footer .fa-twitter,
footer .fa-instagram {
	color: $primary-color;
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer .fa-facebook:hover
footer .fa-twitter:hover,
footer .fa-instagram:hover {
	-ms-transition:color .4s ease-out, background .3s ease-in;
	-moz-transition:color .4s ease-out, background .3s ease-in;
	-webkit-transition:color .4s ease-out, background .3s ease-in;
}

footer .fa-facebook:hover {
	color: #3b5998;
}

footer .fa-twitter:hover {
	color: #4099FF;
}

footer .fa-instagram:hover {
	color: #fbad50;
}

.footer-legal {
	width: 100%;
	position: relative;
	padding: 20px 0 10px 0;
	text-align: center;
}

.footer-content {
	padding: 30px 10px;
	position: relative;
	width: 100%;
	font-size: 13pt;
	color: $footer-color;
	text-align: center;
}

.social-footer {
	padding: 10px;
	position: relative;
	width: 100%;
	font-size: 13pt;
	color: $footer-color;
	text-align: center;
}

/*******************
 **Services CSS   **
 *******************/

.services div:nth-child(2n + 1) {
	clear: left;
}

@media (min-width: $max-width-xs) {
	table.services > thead > tr > th {
		font-size: 20pt;
	}

	table.services > tbody > tr > td {
		font-size: 14pt;
	}
}

@media (max-width: $min-width-from-xs) {
	table.services > thead > tr > th {
		font-size: 14pt;
	}
}

.service-title {
	font-size: 16pt;
}

.service-description {
	margin: 10px 0;
	font-size: 11pt;
	line-height: 1.1;
}

/*******************
 **Open Hours CSS **
 *******************/

table.open-hours {
	margin-top: 40px;
	text-align: center;
}

table.open-hours > tbody > tr > td {
	border: 0;
}

@media (min-width: $max-width-xs) {
	table.open-hours > tbody > tr > td {
		font-size: 22pt;
		padding: 15px;
	}
}

@media (max-width: $min-width-from-xs) {
	table.open-hours > tbody > tr > td {
		font-size: 16pt;
		padding: 10px;
	}
}

/*******************
 **Team CSS       **
 *******************/

.profile-img {
	background: no-repeat 50%;
	background-size: cover;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	display: block;
	margin: auto;
}

/*******************
 **Stylist CSS    **
 *******************/

.stylists div:nth-child(3n + 1) {
	clear: left;
}
